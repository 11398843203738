import { useEffect } from 'react'

/// Create an object with answers initialized
export function useAnswersData ({ data, isLoading, setAnswers, specialData }) {
  useEffect(() => {
    if (!isLoading && data) {
      const newAnswers = []
      // Add answer corresponding to each question in all sections
      data.form_sections.forEach(section => {
        /// Special case for customer form version 3 (China) --> add answer of specialData
        if (data.form_type === 2 && (data.version === 3 || data.version === 4) && specialData) {
          const answerItem = {
            question_id: specialData.id,
            answer_id: null,
            value: null,
            comment: null,
            valid: true,
          }
          newAnswers.push(answerItem)
        }

        section.questions.forEach(questionData => {
          if (questionData.id !== 1001 && questionData.id !== 706) {
            const answerItem = {
              question_id: questionData.id,
              answer_id: null,
              value: null,
              comment: null,
              valid: true,
            }
            newAnswers.push(answerItem)
          }

          // Special case when question is 1001, we need to add to this question the questions of areas
          // so that we can choose one question or another according to what is answered in question 1001
          if ((questionData.id === 1001 || questionData.id === 706) && questionData.special_questions) {
            questionData.special_questions.forEach(specialQuestionData => {
              const specialAnswer = {
                question_id: specialQuestionData.questions[0].id,
                answer_id: null,
                value: null,
                comment: null,
                valid: true
              }
              newAnswers.push(specialAnswer)
            })
          }
        })
      })
      setAnswers(newAnswers)
    }
  }, [data, isLoading, setAnswers])
}
