import Logo from 'ui/common/Logo'
import { IoMdClose } from 'react-icons/io'
import Picker from 'react-mobile-picker'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function LanguageSelector ({ onClose, exit, supportedLangs, locale }) {
  const l = (locale.includes('zh')) ? locale : locale.substring(0, 2)
  const { t } = useTranslation('global')
  const [pickerValue, setPickerValue] = useState({ lang: l })
  const closeTimeoutRef = useRef(null)
  const modalRef = useRef(null)
  const closeButtonRef = useRef(null)
  const [currentFocusIndex, setCurrentFocusIndex] = useState(
    Object.keys(supportedLangs).indexOf(l)
  )
  const languageOptionsRef = useRef([])
  const [usingKeyboard, setUsingKeyboard] = useState(false)

  const languageOptions = Object.keys(supportedLangs)

  useEffect(() => {
    function preventDefault (e) {
      e.preventDefault()
    }

    if (modalRef.current) {
      modalRef.current.focus()
    }

    const closeComponent = () => {
      onClose(pickerValue.lang)
    }

    const startCloseTimeout = () => {
      //closeTimeoutRef.current = window.setTimeout(closeComponent, 3000)
    }

    const clearCloseTimeout = () => {
      if (closeTimeoutRef.current) {
        window.clearTimeout(closeTimeoutRef.current)
      }
    }

    const resetCloseTimeout = () => {
      clearCloseTimeout()
      startCloseTimeout()
    }

    const onTouchStart = () => {
      if (usingKeyboard) setUsingKeyboard(false)
      clearCloseTimeout()
    }

    const onTouchMove = () => {
      if (usingKeyboard) setUsingKeyboard(false)
      clearCloseTimeout()
    }

    const onTouchEnd = () => {
      if (usingKeyboard) setUsingKeyboard(false)
      resetCloseTimeout()
    }

    // start if there is no touch event
    startCloseTimeout()

    const handleKeyDown = (e) => {
      clearCloseTimeout()
      if (!usingKeyboard) {
        if (e.key === 'Tab') {
          setUsingKeyboard(false)
        } else {
          setUsingKeyboard(true)
        }
      }
      if (e.key === 'Escape') {
        e.preventDefault()
        onClose(pickerValue.lang)
        return
      }

      // Handle arrow keys for language selection
      if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
        e.preventDefault()

        const currentIndex = languageOptions.indexOf(pickerValue.lang)
        let newIndex

        if (e.key === 'ArrowUp') {
          newIndex = currentIndex > 0 ? currentIndex - 1 : languageOptions.length - 1
        } else {
          newIndex = currentIndex < languageOptions.length - 1 ? currentIndex + 1 : 0
        }

        setPickerValue({ lang: languageOptions[newIndex] })
        setCurrentFocusIndex(newIndex)

        // Focus the new selected option
        if (languageOptionsRef.current[newIndex]) {
          languageOptionsRef.current[newIndex].focus()
        }
      } else if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault()
        handleLanguageSelect(pickerValue.lang)
      }
    }

    document.body.addEventListener('touchstart', onTouchStart)
    document.body.addEventListener('touchmove', onTouchMove)
    document.body.addEventListener('touchend', onTouchEnd)
    document.body.addEventListener('mousedown', onTouchEnd)
    document.body.addEventListener('touchmove', preventDefault, { passive: false })
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      clearCloseTimeout()
      document.body.removeEventListener('touchstart', onTouchStart)
      document.body.removeEventListener('touchmove', onTouchMove)
      document.body.removeEventListener('touchend', onTouchEnd)
      document.body.removeEventListener('mousedown', onTouchEnd)
      document.body.removeEventListener('touchmove', preventDefault)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose, pickerValue.lang, languageOptions])

  const onTextClicked = (selected) => {
    if (selected) onClose(pickerValue.lang)
  }
  const handleLanguageSelect = (lang) => {
    setPickerValue({ lang })
    onClose(lang)
  }

  useEffect(() => {
    const handleTabKey = (e) => {
      if (e.key === 'Tab') {
        const focusableElements = modalRef.current.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        )
        const firstElement = focusableElements[0]
        const lastElement = focusableElements[focusableElements.length - 1]

        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            e.preventDefault()
            lastElement.focus()
          }
        } else {
          if (document.activeElement === lastElement) {
            e.preventDefault()
            firstElement.focus()
          }
        }
      }
    }

    document.addEventListener('keydown', handleTabKey)
    return () => document.removeEventListener('keydown', handleTabKey)
  }, [])

  return (
    <div
      ref={modalRef}
      className={`w-full h-dvh absolute top-0 left-0 bg-black flex flex-col items-center slide-in z-10 focus-visible:ring-2 ${exit ? 'exit' : ''}`}
      tabIndex={-1}
      role='dialog'
      aria-labelledby='language-title'
      aria-modal='true'
    >
      {/* Close button */}
      <button
        ref={closeButtonRef}
        className='absolute top-5 right-5 text-white text-3xl cursor-pointer'
        onClick={() => onClose(pickerValue.lang)}
        aria-label={t('close_language_selector')}
        title={t('close_language_selector')}
      >
        <IoMdClose />
      </button>

      {/* Logo */}
      <Logo isWhiteLogo />

      {/* Title */}
      <h2 className='mt-8 tablet:mt-[4rem] language-title'>
        {t('select_language')}
      </h2>

      {/* Language Selector */}
      <div
        className='mt-16 w-full small:mt-5 tablet:mt-[5rem] wheel-container'
        role='listbox'
        aria-label={t('select_language')}
      >
        <Picker
          value={pickerValue}
          onChange={setPickerValue}
          wheelMode='natural'
          itemHeight='65'
          height='350'
        >
          <Picker.Column key='lang' name='lang'>
            {Object.keys(supportedLangs).map((name, index) => (
              <Picker.Item key={name} value={name} selected={name === pickerValue.lang}>
                {({ selected }) => (
                  <button
                    // ref={el => (languageOptionsRef.current[index] = el)}
                    className={`${(selected || currentFocusIndex === index) ? `${usingKeyboard ? 'ring-2 ring-white' : ''}` : 'unselected'} wheel-text focus-visible:ring-2 transition-all duration-200 p-2 rounded`}
                    onClick={() => onTextClicked(selected)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault()
                        handleLanguageSelect(name)
                      }
                    }}
                    role='option'
                    aria-selected={selected}
                    aria-label={supportedLangs[name]}
                    tabIndex={selected || currentFocusIndex === index ? 0 : -1}
                  >
                    {supportedLangs[name].toUpperCase()}
                  </button>
                )}
              </Picker.Item>
            ))}
          </Picker.Column>
        </Picker>
      </div>
    </div>
  )
}
