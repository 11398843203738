import Question from './Question'
import { useEffect, useState } from 'react'

const Section = ({ sections, specialData, onAnswerChange, answers, saveForm, formId, hashContinue }) => {
  const [sectionId, setSectionId] = useState(0)
  const [lastQuestionId, setLastQuestionId] = useState(0)
  const [isFirstSection, setIsFirstSection] = useState(true)
  const [questionsIds, setQuestionsIds] = useState([])

  useEffect(() => {
    const questions = sections.flatMap(section => section.questions.map(question => question.id))
    setQuestionsIds(questions)
  }, [])

  const nextSection = () => {
    if (sectionId < sections.length - 1) {
      setLastQuestionId(0)
      setSectionId(sectionId + 1)
    }
    setIsFirstSection(false)
  }

  const previousSection = () => {
    if (sectionId > 0) {
      const newSectionId = sectionId - 1
      const newLastQuestionId = sections[newSectionId].questions.length - 1
      setLastQuestionId(newLastQuestionId)
      setSectionId(newSectionId)
    }
    if (sectionId - 1 === 0) {
      setIsFirstSection(true)
    }
  }

  return (
    <div className='w-full flex-initial flex-grow flex flex-col items-center'>
      <Question
        questions={sections[sectionId].questions}
        specialData={specialData}
        onSectionCompleted={nextSection}
        onPreviousSection={previousSection}
        lastQuestionId={lastQuestionId}
        onAnswerChange={onAnswerChange}
        answers={answers}
        saveForm={saveForm}
        isFirstSection={isFirstSection}
        questionsIds={questionsIds}
        formId={formId}
        hashContinue={hashContinue}
        isLastSection={sectionId === sections.length - 1}
      />
    </div>
  )
}

export default Section
