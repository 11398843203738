import { StarFilled } from './StarFilled'
import { useTranslation } from 'react-i18next'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import { useEffect, useRef, useState } from 'react'

export default function Legend ({ breakLegend }) {
  const { t } = useTranslation('global')
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const buttonRef = useRef(null)
  const toggleTooltip = (e) => {
    e.preventDefault()
    setIsTooltipVisible(prev => !prev)
  }
  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
      case ' ':
        e.preventDefault()
        toggleTooltip(e)
        break
      case 'Escape':
        if (isTooltipVisible) {
          e.preventDefault()
          setIsTooltipVisible(false)
          buttonRef.current?.focus()
        }
        break
      case 'Tab':
        if (isTooltipVisible) {
          e.preventDefault()
          setIsTooltipVisible(false)
        }
        break
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsTooltipVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <button
      ref={buttonRef}
      className='absolute top-1 -right-6 ml-2 cursor-pointer text-blue-500'
      role='button'
      aria-label={t('info_tooltip')}
      onClick={toggleTooltip}
      onKeyDown={(e) => handleKeyDown(e)}
      aria-expanded={isTooltipVisible}
      tabIndex='0'
    >
      <IoIosInformationCircleOutline
        className='hover:opacity-80'
        size={20}
        color='#5c5c5c'
        aria-hidden='true'
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        onClick={toggleTooltip}
      />

      {isTooltipVisible &&
        <div
          className='absolute -right-4 mt-2 p-2 text-xs text-white bg-gray-200 rounded-lg transition-opacity'
          role='tooltip'
          aria-hidden={!isTooltipVisible}
        >
          <div className={`mb-5 flex items-center gap-1 mt-2 ${breakLegend ? 'flex-col' : ''}`}>
            <div className='flex items-center gap-1'>
              <StarFilled aria-label={`1 ${t('stars')}`} isSmall />
              <p className='text-center italic text-sm text-gray-500 font-thin mr-2'>
                {t('very_bad')}
              </p>
            </div>
            <div className='flex items-center gap-1'>
              <div className='flex gap-[2px]'>
                {[0, 1, 2, 3, 4].map((i) => <StarFilled
                  aria-label={`${i} ${t('stars')}`} key={i} aria-hidden='true'
                  isSmall
                                            />)}
              </div>
              <p className='text-center italic text-sm text-gray-500 font-thin'>
                {t('excellent')}
              </p>
            </div>
          </div>
        </div>}
    </button>
  )
}
