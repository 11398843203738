import blackLogo from 'assets/images/blackLogo.png'
import whiteLogo from 'assets/images/whiteLogo.png'
import { useTranslation } from 'react-i18next'

const Logo = ({isWhiteLogo}) => {
  const { t } = useTranslation('global')

  return (
    <div className='w-full flex flex-col justify-center items-center mt-20 small:mt-5 mb-5 tablet:mb-16'>
      <div className='h-[3.75rem] tablet:h-[5rem]'>
        <img src={isWhiteLogo ? whiteLogo : blackLogo} alt={t('zara_logo')} className='block h-full object-cover' />
      </div>
    </div>
  )
}

export default Logo
