import { WorldIcon } from 'ui/common/icons/WorldIcon'
import { useTranslation } from 'react-i18next'

export function LanguageSelectorTab ({ locale, onClick }) {
  const localeShortCode = locale.split('-')[0]
  const { t } = useTranslation('global')

  return (
    <button
      className='cursor-pointer absolute bottom-[40%] left-0 px-2 py-4 bg-black flex flex-col items-center gap-3 rounded-r-sm rounded-b-sm language-transition focus-visible:ring-2'
      onClick={onClick}
      tabIndex='0'
      title={t('change_language')}
      aria-label={t('change_language')}
    >
      <span className='locale-text'>{localeShortCode}</span>
      <WorldIcon aria-hidden='true' />
    </button>
  )
}
