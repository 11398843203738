export const DEBUG = false;
export const DEBUG_STORE = 'UmJSWGZQcDR6Vk1wWnladWxjcFlrZz09';

export const DOMAIN_URL_DEV = 'https://dev.leapco.pro/';
export const DOMAIN_URL_PRO = 'https://leapco.pro/'
export const DOMAIN_URL_PRO_CN = 'https://leap.inditex.cn/'

export const FIRST_QUESTION_ID = DEBUG ? 704 : 644;
export const POPUP_FIRST_QUESTION_IDS = [1316, 1436];
export const POPUP_SPECIAL_QUESTIONS_IDS = [1319, 1439]
export const POPUP_PREV_SPECIAL_QUESTIONS_IDS = [1318, 1437]

export const CUSTOMER_API_URI = 'api/v1/resources/cx_customer_feedback/'
export const CUSTOMER_CORNER_API_URI = 'api/v1/resources/cx_customer_corners/';

export const HASH_KEY = 'mNPXNjILl9raekq1MRQ0OtF-8EfIwpb-OotrAC3gadryEsQS5Xs_w7giY-hOXoJCfy4jM3t2vv'
export const HASH_IV = 'x880008510993'
