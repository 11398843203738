import { useState } from 'react'

export function CheckboxItem ({ answer, onSelected, initialState }) {
  const [selected, setSelected] = useState(initialState)

  const toggleSelected = (event) => {
    event.preventDefault()
    const newState = !selected
    setSelected(newState)
    onSelected(answer.id, newState)
  }

  return (
    <button
      className={`whiteBtn ${selected ? 'selected' : ''} btn-checkbox`}
      onClick={(e) => toggleSelected(e)}
      aria-pressed={selected}
      aria-label={answer.text}
    >
      {answer.text}
    </button>
  )
}
