import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'ui/utils/utils'
import { StarOutlined } from 'ui/common/StarOutlined'
import { StarFilled } from 'ui/common/StarFilled'
import Rating from 'react-rating'

export function NewAreaRateItem ({ savedAnswer, question, onAnswerChange }) {
  const { t } = useTranslation('global')

  const [rating, setRating] = useState(savedAnswer.value)
  const [comment, setComment] = useState(savedAnswer.comment)
  const [showInput, setShowInput] = useState(savedAnswer.comment !== null || savedAnswer.comment == '')
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const textareaRef = useRef(null)
  const starsRef = useRef([])
  const [usingKeyboard, setUsingKeyboard] = useState(false)

  useEffect(() => {
    onAnswerChange(question.id, rating, comment)
  }, [rating, comment])

  useEffect(() => {
    const checkIsMobile = isMobile()
    setIsMobileDevice(checkIsMobile)
  }, [])

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Tab' || e.key.includes('Arrow')) {
        setUsingKeyboard(true)
      }
    }

    const handleMouseOrTouch = () => {
      console.log('using keyboard false')
      setUsingKeyboard(false)
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('mousedown', handleMouseOrTouch)
    document.addEventListener('touchstart', handleMouseOrTouch)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleMouseOrTouch)
      document.removeEventListener('touchstart', handleMouseOrTouch)
    }
  })

  const handleRating = (newRate) => {
    if (newRate === rating) {
      setRating(null)
    } else {
      setRating(newRate)
    }
  }

  const handleKeyDown = (event, starValue) => {
    let newRating = rating
    switch (event.key) {
      case ' ':
      case 'Enter':
        event.preventDefault()
        newRating = starValue
        break
      case 'ArrowRight':
      case 'ArrowUp':
        event.preventDefault()
        newRating = Math.min(5, (rating || 0) + 1)
        break
      case 'ArrowLeft':
      case 'ArrowDown':
        event.preventDefault()
        newRating = Math.max(1, (rating || 2) - 1)
        break
      case 'Home':
        event.preventDefault()
        newRating = 1
        break
      case 'End':
        event.preventDefault()
        newRating = 5
        break
      default:
        return
    }
    starsRef.current[newRating - 1]?.focus()
    if (newRating === rating) {
      setRating(null)
    } else {
      setRating(newRating)
    }
  }

  const handleCommentChange = (event) => {
    setComment(event.target.value)
  }

  const handleUnfocus = () => {
    if (!comment) {
      setShowInput(false)
    }
  }

  const handleClickOutside = (e) => {
    if (textareaRef.current && !textareaRef.current.contains(e.target)) {
      handleUnfocus()
    }
  }

  return (
    <div className='flex flex-col items-center gap-4'>
      <div className='flex w-full rating items-center mb-5' role='radiogroup' aria-label='Rating'>
        {[1, 2, 3, 4, 5].map((value, i) => (
          <button
            key={value}
            ref={(el) => (starsRef.current[i] = el)}
            type='button'
            role='radio'
            aria-checked={rating === value}
            aria-label={`${value} ${t('stars')}`}
            title={`${value} ${t('stars')}`}
            tabIndex={0}
            onClick={() => handleRating(value)}
            onKeyDown={(e) => handleKeyDown(e, value)}
            className={`star ${rating >= value ? 'filled' : 'outlined'} star-button ${usingKeyboard ? '' : 'focus-hide'}`}
          >
            {rating >= value ? <StarFilled isMobileDevice aria-hidden='true' /> : <StarOutlined isMobileDevice aria-hidden='true' />}
          </button>
        ))}
      </div>
      <div className='flex align-center justify-center w-full'>
        {showInput
          ? <textarea
              ref={textareaRef}
              className='tellMore'
              value={comment ?? ''}
              id={`textarea-${question.id}`}
              rows={isMobileDevice ? 3 : 5}
              placeholder={question.description}
              onChange={handleCommentChange}
              aria-label='Give more information about your answer'
            />
          : <button className='whiteBtn' aria-expanded={showInput} onClick={() => setShowInput(true)}>{question.description}</button>}
      </div>
    </div>
  )
}
